<template>
  <div class="filter filter-theme-info">
    <div class="flex flex-col space-y-3">
      <Subject-Type
        v-model="form.sbtKey"
        :class="isFixSb ? 'pointer-events-none opacity-60' : ''"
      />

      <Subject
        v-model="form.sbjKey"
        :sbt-key="form.sbtKey?.id"
        :class="isFixSb ? 'pointer-events-none opacity-60' : ''"
      />

      <Period v-model="form.period" />

      <Ex-Period v-model="form.exPeriod" />

      <Conf v-model="form.confIndex"></Conf>

      <Attributes v-model="form.attribute" :sbj-key="form.sbjKey?.id" />

      <Q-Types v-model="form.qType" />

      <hr class="my-4" />

      <Rules
        v-model="form.act"
        v-model:childModelValue="form.actNo"
        type="act"
        pph="'ex:民法'"
        cph="'ex:225-1'"
        title="法規 (複選)"
      ></Rules>

      <Rules
        v-model="form.reg"
        v-model:childModelValue="form.regNo"
        type="reg"
        pph="ex.政府採購法施行細則"
        cph="'ex:5-1'"
        title="命令/行政規則 (複選)"
      ></Rules>

      <Rule
        v-model="form.ar"
        type="ar"
        pph="'ex.台財融（一）字0901000354號"
        title="行政函釋 (複選)"
      ></Rule>
      <Rule
        v-model="form.prec"
        type="prec"
        pph="ex.94 年台上字第 4929 號"
        title="司法解釋判例 (複選)"
      ></Rule>
      <Rule
        v-model="form.judg"
        type="judg"
        pph="ex.最高法院104年度台上字第3222號判決"
        title="司法解釋判決 (複選)"
      ></Rule>
      <Rule
        v-model="form.acc"
        type="acc"
        pph="ex.IFRS9金融工具"
        title="會計公報 (複選)"
      ></Rule>
      <hr class="my-4" />

      <Score v-model="form.avgCorrectRate" />
      <QCnt v-model="form.qCnt" :min="1" />
      <IdName
        v-model:name="form.keyword"
        v-model:id="form.key"
        :id-label="'試題ID'"
        :name-label="'關鍵字'"
      />
      <!-- <Qcnt v-model="form.qCnt" /> -->
    </div>
    <div class="flex space-x-6 py-4 justify-center">
      <el-button size="large" type="text" @click="reset">重新填寫 </el-button>
      <el-button size="large" type="danger" @click="add_"
        >加入篩選條件</el-button
      >
    </div>

    <Results :rows="results" @remove="remove" @clear="clear" />

    <div class="py-6 flex justify-center">
      <El-Button
        :disabled="!results.length"
        size="large"
        type="primary"
        round
        class="text-xl min-w-[200px]"
        @click="submit"
        >顯示試題</El-Button
      >
    </div>
  </div>
</template>

<script>
import Rule from './form/_rule/Index.vue';
import Rules from './form/_rule/Multi.vue';

import Conf from './form/conf/Index.vue';
import SubjectType from './form/subjectType/Index.vue';
import Subject from './form/subject/Radio.vue';

import Period from './form/period/Index.vue';
import ExPeriod from './form/exPeriod/Index.vue';
import Results from './_Results.vue';
import Score from './form/score/Index.vue';
import QCnt from './form/qCnt/Single.vue';

import Attributes from './form/attributes/Index.vue';
import QTypes from './form/qType/Index.vue';

import IdName from './form/IdName.vue';

import useFilter from './useFilter';

import { ElButton } from 'element-plus';

import ApiQuestion from '@/api/Question';
import { ElMessage } from 'element-plus';

export default {
  components: {
    Conf,
    Attributes,
    SubjectType,
    Subject,
    Rule,
    Period,
    ExPeriod,
    Score,
    // Qcnt,
    QCnt,
    Results,
    ElButton,
    QTypes,
    Rules,
    IdName,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { form, add, remove, reset, results, getFilterId, clear, isFixSb } =
      useFilter({
        excludeResetKeys: ['sbjKey', 'sbtKey'],
        defaultForm: {
          // type: 'wrong',
          name: null,
          keyword: null,
          key: null,
          cKey: null,
          sbtKey: {},
          sbjKey: null,
          act: [],
          actNo: [],
          reg: [],
          regNo: [],
          ar: [],
          prec: [],
          judg: [],
          acc: [],
          confIndex: [{ id: 'all', name: '不拘' }],
          qType: [{ id: 'all', name: '不拘' }],
          attribute: [{ id: 'all', name: '不拘' }],
          period: { id: 'all', name: '不拘' },
          exPeriod: { id: 'all', name: '不拘' },
          avgCorrectRate: { id: 'all', name: '不拘' },
          qCnt: { id: '50', name: '50' },
          // qCnt: { id: 'all', name: '不拘' },
        },
      });

    form.type = 'wrong';
    const add_ = () => {
      let valid = true;

      if (!form.sbtKey || !form.sbjKey) {
        valid = false;
      }
      if (!valid) {
        ElMessage({
          message: '請先選擇科目種類/考試科目"',
          center: true,
        });
      } else {
        add();
      }
    };
    const submit = () => {
      getFilterId({ API: ApiQuestion.filterId }).then((res) => {
        if (res.code === 1) {
          emit('update', { filterId: res.data.filterId });
        } else {
          ElMessage({
            message: '無法取得filter id',
            center: true,
          });
        }
      });
    };
    return {
      form,
      add_,
      remove,
      reset,
      results,
      submit,
      clear,
      isFixSb,
    };
  },
};
</script>
